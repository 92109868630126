import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";
import ReactTooltip from "react-tooltip";
import ModalVideo from "react-modal-video";
import Modal from "react-modal";
import Social from "./Social";

const Portfolio = () => {

  // for modal details
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);

  // for modal details method
  function toggleModalOne() {
    setIsOpen1(!isOpen1);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }

  return (
    <>
      <Gallery>
        <div className="container">
          <div className="tokyo_tm_portfolio">
            <div className="tokyo_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Portfolio</span>
                  <h3>Portfolio professionnel</h3>
                </div>
              </div>
            </div>
            {/* END TOKYO_TM_TITLE */}

            <div className="portfolio_filter">
              <Tabs>
                {/* END TABLIST */}
                <div className="list_wrapper">
                  <TabPanel>
                    <ul className="portfolio_list">
                    <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-f-tech.png"
                              alt="Details"
                              data-tip
                              data-for="detail7"
                              onClick={toggleModalSeven}
                            />

                            <ReactTooltip
                              id="detail7"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>F-TECH</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                    <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-la-maison-de-la-peau.png"
                              alt="Details"
                              data-tip
                              data-for="detail6"
                              onClick={toggleModalSix}
                            />

                            <ReactTooltip
                              id="detail6"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>La Maison de la peau</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                    <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-pradim.png"
                              alt="Details"
                              data-tip
                              data-for="detail5"
                              onClick={toggleModalFive}
                            />

                            <ReactTooltip
                              id="detail5"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Pradim</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-le-phare-de-l-espiguette-grau-du-roi.png"
                              alt="Details"
                              data-tip
                              data-for="detail4"
                              onClick={toggleModalFour}
                            />

                            <ReactTooltip
                              id="detail4"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div className="bull-click">
                                <h5>Phare de l'Espiguette</h5>
                                <span>Détails</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-contisud-productions.png"
                              alt="Details"
                              data-tip
                              data-for="detail3"
                              onClick={toggleModalThree}
                            />

                            <ReactTooltip
                              id="detail3"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div className="bull-click">
                                <h5>Contisud Productions</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-chateau-chenaie.png"
                              alt="Details"
                              data-tip
                              data-for="detail2"
                              onClick={toggleModalTwo}
                            />

                            <ReactTooltip
                              id="detail2"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Château Chenaie</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-nicollin.png"
                              alt="Details"
                              data-tip
                              data-for="detail1"
                              onClick={toggleModalOne}
                            />

                            <ReactTooltip
                              id="detail1"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Nicollin</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}

                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END ALL PORTFOLIO GALLERY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li data-aos="fade-right" data-aos-duration="1200">
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <Item
                              original="assets/img/portfolio/4.jpg"
                              thumbnail="assets/img/portfolio/4.jpg"
                              width={500}
                              height={550}
                            >
                              {({ ref, open }) => (
                                <img
                                  src="assets/img/portfolio/4.jpg"
                                  alt="Childhood"
                                  data-tip
                                  data-for="shot"
                                  role="button"
                                  ref={ref}
                                  onClick={open}
                                />
                              )}
                            </Item>
                            <ReactTooltip
                              id="shot"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Derek Smith</h5>
                                <span>Shot</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <Item
                              original="assets/img/portfolio/3.jpg"
                              thumbnail="assets/img/portfolio/3.jpg"
                              width={500}
                              height={550}
                            >
                              {({ ref, open }) => (
                                <img
                                  src="assets/img/portfolio/3.jpg"
                                  alt="Childhood"
                                  data-tip
                                  data-for="shot"
                                  role="button"
                                  ref={ref}
                                  onClick={open}
                                />
                              )}
                            </Item>
                            <ReactTooltip
                              id="shot2"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Gloria jenkins</h5>
                                <span>Shot</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END PHOTOGRAHY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li data-aos="fade-right" data-aos-duration="1200">
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/mockup-le-phare-de-l-espiguette-grau-du-roi.png"
                              alt="Details"
                              data-tip
                              data-for="detail"
                              onClick={toggleModalThree}
                            />

                            <ReactTooltip
                              id="detail"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Phare de l'Espiguette</h5>
                                <span>Détails</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <img
                              src="assets/img/portfolio/7.jpg"
                              alt="Details"
                              data-tip
                              data-for="detail2"
                              onClick={toggleModalFour}
                            />

                            <ReactTooltip
                              id="detail2"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Ave Simone</h5>
                                <span>Details</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END DETAILS */}
                    </ul>
                    {/* END DETAILS GALLERY */}
                  </TabPanel>
                  {/* END CREATIVE PORTFOLIO GALLERY */}
                </div>
                {/* END LIST WRAPPER */}
              </Tabs>
            </div>
          </div>
        </div>
      </Gallery>
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen7}
        onRequestClose={toggleModalSeven}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSeven}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-f-tech.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>F-Tech</h3>
                <span>Détails</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  F-TECH, fabriquant de pièces de structure pour l'aéronautique et la défense.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Créations de modules sur-mesure personalisés pour les besoins du clients.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>F-Tech</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2024</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.f-tech.fr/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-f-tech-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-f-tech-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen6}
        onRequestClose={toggleModalSix}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSix}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-la-maison-de-la-peau.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>La Maison de la peau</h3>
                <span>Détails</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  La Maison de la Peau, votre institut de beauté dans le centre-ville de Nimes pour vos soins esthétiques de la peau et du visage.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Créations de modules sur-mesure personalisés pour les besoins du clients.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>La Maison de la peau</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2024</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.lamaisondelapeau.fr/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-la-maison-de-la-peau-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-la-maison-de-la-peau-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-pradim.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Pradim</h3>
                <span>Détails</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    Crée en 1990 le Groupe PRADIM développe avec le concours de ses filiales des projets immobiliers dans le grand Sud de la France. Il étend aujourd’hui ses compétences à l’ensemble des métiers de l’immobilier.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Créations de modules sur-mesure personalisés pour les besoins du clients.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>Groupe Pradim</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2024</span>
                    </li>
                    <li>
                      <a className="first" href="https://pradim.fr/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-pradim-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-pradim-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-le-phare-de-l-espiguette-grau-du-roi.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Phare de l'Espiguette</h3>
                <span>Détails</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    Le 26 février 2019, il a été affecté au Conservatoire du littoral avec lequel la Commune du Grau du Roi et son Office du tourisme ont développé un projet d'ouverture au public permettant la restauration complète de l'édifice et l'aménagement d'un espace muséographique.

                    Après sa restauration complète, le phare est ouvert au public depuis le milieu de l'année 2023.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Créations de modules sur-mesure personalisés pour les besoins du clients. Site réalisé en binôme au sein de l'agence.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>Le Grau Du Roi Développement</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2023</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.phare-espiguette.fr/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-le-phare-de-l-espiguette-grau-du-roi-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-le-phare-de-l-espiguette-grau-du-roi-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/portfolio/mockup-contisud-productions.png" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-contisud-productions.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Contisud Productions</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  Depuis 2007 CONTISUD PRODUCTIONS, installée à Montpellier, propose tous les services de productions pour des prises de vues photographiques et films courts.
                  </p>
                  <p>
                  Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                  Créations de modules sur-mesure personalisés pour les besoins du clients. Site réalisé en binôme au sein de l'agence.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>Contisud Productions</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2023</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.contisudproductions.com/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                      <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="assets/img/portfolio/mockup-contisud-productions-ordinateur-portable.png" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-contisud-productions-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-contisud-productions-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
            {/* START MODAL FOR PORTFOLIO DETAILS */}
            <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/portfolio/mockup-chateau-chenaie.png" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-chateau-chenaie.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Château Chenaie</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  C'est aux portes des Cévennes, dans le petit village de Caussiniojouls que le Château Chênaie produit des grands vins depuis maintenant 6 générations. Sur une surface d'une quarantaine de parcelles de vignes dans la garrigue, la famille Chabbert élabore depuis 1994 des vins avec un grand terroir de schiste sur l'appellation Faugères.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress avec Woocommerce. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Site réalisé en binôme au sein de l'agence.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>Château Chenaie</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2023</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.chateau-chenaie-vins.fr/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                      <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="(assets/img/portfolio/mockup-chateau-chenaie.png)" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-chateau-chenaie-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-chateau-chenaie-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
            {/* START MODAL FOR PORTFOLIO DETAILS */}
            <Modal
        isOpen={isOpen1}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/portfolio/mockup-nicollin.png" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/mockup-nicollin.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Nicollin</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  Le Groupe Nicollin est un ensemble d'entreprises de nettoyage urbain, de collecte et de retraitement des déchets ménagers et industriels.
                  </p>
                  <p>
                    Développement et intégration de la maquette graphique du site sur Wordpress. Utilisation des technologies suivantes: HTML, PHP, SASS et JS.
                    Site réalisé en binôme au sein de l'agence.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Client</span>
                      <span>Nicollin</span>
                    </li>
                    <li>
                      <span className="first">Droits</span>
                      <a class="agence-sweep" target="_blank" rel="noreferrer">Agence Sweep</a>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>2022</span>
                    </li>
                    <li>
                      <a className="first" href="https://www.groupenicollin.com/" target="_blank" rel="noreferrer">Visiter le site</a>
                    </li>
                    {/*<li>
                      <span className="first">Share</span>
                      <Social />*/}
                      {/* END SOCIAL SHARE */}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="assets/img/portfolio/mockup-nicollin-ordinateur-portable.png" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-nicollin-ordinateur-portable.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/mockup-nicollin-mobile.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}

    </>
  );
};

export default Portfolio;
