import React from "react";

const CopyRight = () => {
  return (
    <div className="copyright">
      <p>
        &copy; {new Date().getFullYear()} Arnaud Morvan {/*<br /> Created by
        <a
          href="https://themeforest.net/user/ib-themes"
          target="_blank"
          rel="noreferrer"
        >
          Ib-Themes
  </a>*/}
      </p>
    </div>
  );
};

export default CopyRight;
