import React from "react";

const Intro = () => {
  const introContent = {
    image: "assets/img/slider/1.jpg",
    name: "Arnaud Morvan",
    designation: "Développeur Font-End",
    text: (
      <>
        <p>
          Mon parcours professionnel et personnel a fait de moi une personne dont les valeurs sont la rigueur, l’esprit d’équipe, la capacité d’adaptation, l’ouverture d’esprit et l’implication.
        </p>
        <p>
          Ce sont ces compétences, ces valeurs et expériences tant personnelles que professionnelles que je me propose de mettre au service de votre entreprise.
        </p>
      </>
    ),
  };

  return (
    <>
      {/*<div className="top_author_image">
        <img src={introContent.image} alt="about" />
  </div>*/}
      <div className="about_title">
        <h3>{introContent.name}</h3>
        <span>{introContent.designation}</span>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
